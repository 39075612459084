@import "_utils";
html,
body{font-family:'Poppins-Regular',sans-serif !important}
b{
    font-family:'Poppins-Bold',sans-serif !important
}
.btn{
    width: auto;
    color: white !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    border: 1px solid #0FA6E5;
    background-color: #0FA6E5;
    text-transform: uppercase;
    height: auto;
    padding: 5px 25px;
    margin: 0 auto;
    border-radius: 3px;
	&:hover {
		background: lighten(#0FA6E5,5%);
	}
}